import React from 'react'
import { CarouselNav, Type } from '@/components/common'
import {
  Guild,
  InvestCircle,
  MovieSpecial,
  TicketOutline,
} from '@/components/svg'
import useCarousel from '@/services/hooks/useCarousel'
import { HowItWorksCard } from './HowItWorksCard'

export const HowItWorks: React.FC = () => {
  const [
    scrollerRef,
    { canScrollBack, canScrollForward, scrollBack, scrollForward },
  ] = useCarousel<HTMLDivElement>()

  return (
    <div className="w-full max-w-[1168px] mx-auto px-4 py-12">
      <Type
        as="p"
        variant={['eyebrow-md', 'md:eyebrow-lg']}
        className="mb-1 text-core-gray-600"
      >
        How it works
      </Type>
      <Type
        as="h2"
        variant={['heading-xs', 'md:heading-xl']}
        className="mb-3 font-bold"
      >
        The Angel Model
      </Type>
      <Type as="p" variant={['caption-lg', 'md:paragraph-lg']} className="mb-6">
        As an investor, you’re part of a movement to create change in the
        entertainment industry.
      </Type>

      <div className="relative">
        <div
          ref={scrollerRef}
          className="hide-scrollbar relative flex gap-4 snap-x snap-mandatory overflow-scroll -mx-4 px-4"
        >
          <HowItWorksCard icon={<Guild />} title="Angel Guild">
            <Type as="p" variant="paragraph-md" className="mb-4">
              Every film and show concept is voted on by the Angel Guild—a group
              of enthusiastic fans and investors.
            </Type>
            <Type as="p" variant="paragraph-md">
              Guild members also get exclusive benefits such as Early Access,
              movie tickets to all Angel theatrical releases, etc.
            </Type>
          </HowItWorksCard>
          <HowItWorksCard icon={<InvestCircle />} title="Support the Project">
            <Type as="p" variant="paragraph-md" className="mb-4">
              Angel Studios gauges interest in the project for potential
              investment.
            </Type>
            <Type as="p" variant="paragraph-md">
              Once investment interest becomes substantial, the filmmakers raise
              money for production costs.
            </Type>
          </HowItWorksCard>
          <HowItWorksCard icon={<MovieSpecial />} title="Production">
            <Type as="p" variant="paragraph-md" className="mb-4">
              The filmmakers begin filming or post-production, with regular
              updates for investors.
            </Type>
            <Type as="p" variant="paragraph-md">
              You can support the project at this stage by joining livestreams,
              engaging with their social platforms and spread the word.
            </Type>
          </HowItWorksCard>
          <HowItWorksCard icon={<TicketOutline />} title="Release">
            <Type as="p" variant="paragraph-md" className="mb-4">
              Angel Studios will distribute the project in theaters or directly
              in-app.
            </Type>
            <Type as="p" variant="paragraph-md">
              Films released in theaters eventually become available on
              Angel.com and the Angel app.
            </Type>
          </HowItWorksCard>
        </div>
        <CarouselNav
          active={canScrollBack}
          direction="back"
          onClick={scrollBack}
          orientation="horizontal"
          className="-ml-3"
        />
        <CarouselNav
          active={canScrollForward}
          direction="forward"
          onClick={scrollForward}
          orientation="horizontal"
          className="-mr-3"
        />
      </div>

      <Type as="p" variant="caption-sm" className="text-core-gray-600">
        This represents the ideal journey of Angel Studios films and series, and
        some phases might be different based on the unique needs of each
        project.
      </Type>
    </div>
  )
}
