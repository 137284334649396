import React from 'react'
import { Type } from '@/components/common'

interface Props {
  children: React.ReactNode
  icon: React.ReactNode
  title: string
}

export const HowItWorksCard: React.FC<Props> = ({ children, icon, title }) => {
  return (
    <div className="bg-white px-6 py-8 mb-10 rounded-3xl shadow-light w-[80vw] max-w-[320px] shrink-0 snap-center snap-alway">
      <div className="h-8 w-8 mb-2">{icon}</div>
      <Type as="h3" variant="title-lg" className="font-bold mb-4">
        {title}
      </Type>
      {children}
    </div>
  )
}
