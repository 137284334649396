import React from 'react'
import { Type } from '@/components/common'
import { CheckCircleFill } from '@/components/svg'

interface Props {
  title: string
  text: string
}

const GuildSectionItem: React.FC<Props> = ({ title, text }) => {
  return (
    <div className="flex gap-4 text-left">
      <CheckCircleFill className="text-core-oxide-bright h-6 w-6 shrink-0" />
      <div>
        <Type as="h3" variant="title-sm" className="font-bold mb-2">
          {title}
        </Type>
        <Type as="p" variant="paragraph-md" className="text-core-gray-400">
          {text}
        </Type>
      </div>
    </div>
  )
}

export default GuildSectionItem
