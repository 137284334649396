import React from 'react'
import { Button, Type, VideoBox } from '@/components/common'
import { GuildBronzeShield } from '@/components/svg'
import GuildSectionItem from './GuildSectionItem'

export const GuildSection: React.FC = () => {
  return (
    <div className="bg-core-gray-950 pt-14 pb-[4.5rem] px-6">
      <div className="flex flex-col gap-10 text-white text-center max-w-[1168px] mx-auto md:gap-20">
        <div className="max-w-[576px] mx-auto">
          <GuildBronzeShield className="mx-auto mb-2 md:mb-4" />
          <Type
            as="h2"
            variant={['heading-xs', 'md:heading-xl']}
            className="font-bold pb-3"
          >
            Angel Guild
          </Type>
          <Type as="p" variant="caption-lg" className="text-core-gray-400">
            All investors get Angel Guild access to review, vote on and
            green-light upcoming projects.
          </Type>
        </div>

        <div className="flex flex-col gap-10 lg:flex-row lg:items-start">
          <div className="w-full lg:max-w-[626px]">
            <VideoBox
              campaignSlug="angel-guild"
              id="angel-guild"
              video="aant3mHluj0"
              className="rounded-2xl overflow-hidden"
            />
          </div>

          <div className="flex flex-col gap-8 lg:gap-16">
            <GuildSectionItem
              title="Preview new ideas for shows and movies."
              text="Get exclusive access to film proposals, and help directors create
            things you actually want to watch."
            />
            <GuildSectionItem
              title="Give feedback on film or show proposals you love."
              text="Answer a few questions to cast your vote. Filmmakers review your feedback to make your favorites even better."
            />
            <GuildSectionItem
              title="Get updated on projects you’re excited about."
              text="Receive regular updates on new shows, movies, and more. You’re an insider in the entertainment industry."
            />
          </div>
        </div>

        <a
          href="https://www.angel.com/guild/join?utm_medium=web&utm_campaign=guild-memberships&utm_source=sitenav"
          target="_blank"
        >
          <Button color="white">Learn more</Button>
        </a>
      </div>
    </div>
  )
}
