import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { CampaignSection } from '@/components/common'
import { Campaign } from '@/services/api/campaign'
import useAuth from '@/services/hooks/useAuth'
import useCampaigns from '@/services/hooks/useCampaigns'
import { GuildSection } from './GuildSection'
import { HowItWorks } from './HowItWorks'
import { LaunchHero } from './LaunchHero'

// closing soonest to latest
const sortEstimateClosingSoonest = (a: Campaign, b: Campaign) => {
  // handle null cases
  if (b.hoursRemainingEstimate === null && a.hoursRemainingEstimate === null) {
    return 0
  } else if (a.hoursRemainingEstimate === null) {
    return 1
  } else if (b.hoursRemainingEstimate === null) {
    return -1
  }

  return a.hoursRemainingEstimate - b.hoursRemainingEstimate
}

export interface Props {
  allCampaigns: Campaign[]
}

const LaunchLandingPage: React.FC<Props> = ({ allCampaigns }) => {
  const { openModal: openAuthModal } = useAuth()
  const router = useRouter()
  const { campaigns = allCampaigns } = useCampaigns()

  useEffect(() => {
    if (router.query.auth) {
      openAuthModal()
    }
  }, [router, openAuthModal])

  const currentlyFunding = campaigns
    .filter((campaign) => campaign.status === 'currently_funding')
    .sort(sortEstimateClosingSoonest)

  return (
    <main className="h-full min-h-screen flex-grow bg-core-gray-100">
      <LaunchHero />

      {!!currentlyFunding.length && (
        <CampaignSection
          campaigns={currentlyFunding}
          className="mx-auto pt-12 md:pt-20"
          data-cy="currently-funding-group"
          preTitle="Invest"
          title="Featured Projects"
          text="Explore groundbreaking films and series seeking your support."
        />
      )}

      <HowItWorks />

      <GuildSection />
    </main>
  )
}

export default LaunchLandingPage
