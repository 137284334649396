import React from 'react'
import { Button, Type } from '@/components/common'
import { useExperiment } from '@/experimentation'
import useAuth from '@/services/hooks/useAuth'

export const LaunchHero: React.FC = () => {
  const { user, openModal: openAuthModal, startAuth } = useAuth()
  const [isOAuth2] = useExperiment('ellis_island_oauth2', true)

  return (
    <div className="text-center mt-10 mb-8 mx-3">
      <Type
        as="h2"
        variant="heading-lg"
        className="flex flex-wrap gap-x-2 mb-4 font-bold justify-center *:shrink-0"
      >
        <span>Be Part of</span>
        <span>Stories that Inspire</span>
      </Type>
      <Type as="p" variant="title-sm" className="mb-14">
        Empower films and series that celebrate truth, inspire change and uplift
        the world.
      </Type>

      {user === null && (
        <div className="flex flex-col gap-2 items-center">
          <Button
            color="black"
            onClick={() => {
              if (isOAuth2) {
                startAuth()
              } else {
                openAuthModal('register')
              }
            }}
          >
            Create Account
          </Button>
          <Button
            variant="text"
            color="black"
            onClick={() => {
              if (isOAuth2) {
                startAuth()
              } else {
                openAuthModal('register')
              }
            }}
          >
            Log In
          </Button>
        </div>
      )}
    </div>
  )
}
